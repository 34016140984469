import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./BottomBar.scss";

/**
 * This component renders the footer bar of the application.
 * It contains the submit button with its checked circle and the progressBar.
 */
class BottomBar extends Component {
  render() {
    const props = this.props;
    return (
      <Container fluid className="bottomContainer">
        <Row className="justify-content-center mb-4 mt-2">
          <Col sm={3} md={2} lg={2}>
            <div className="round mr-5" style={{float:"right"}}>
                <input type="checkbox" id="checkbox" checked={props.checked} readOnly />
                <label htmlFor="checkbox"></label>
            </div>
          </Col>
          <Col sm={6} md={3} lg={3} className="mr-5">
            <Button
              id="submitButton"
              onClick={props.onClick}
              size={"lg"}
              variant="custom"
              disabled={props.disabled}
            >
              Mes émissions
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <ProgressBar
              variant="custom"
              now={props.progress}
              label={`Avancement : ${props.progress}%`}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

BottomBar.propTypes = {
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  progress: PropTypes.number.isRequired,
};

export default BottomBar;
