import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types"

/**
 * This component renders a CheckBox with the
 * right props.
 */
class CheckboxComponent extends Component {
  render() {
    const props = this.props;
    return (
      <Form.Group controlId={props.controlId}>
        <Form.Check
          type="checkbox"
          label="Section non applicable à mon organisation"
          onChange={props.onChange}
          checked={props.checked}
        ></Form.Check>
      </Form.Group>
    );
  }
}

CheckboxComponent.propTypes = {
  controlId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
}


export default CheckboxComponent;
