import React, { Component } from "react";
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import PropTypes from "prop-types"

/**
 * This component renders a unique controlled input
 * field for the form with the right props
 */
class UniqueInputField extends Component {
  render() {
    const props = this.props;
    return (
      <Form.Group controlId={props.controlId}>
        <Row className="justify-content-center">
          <Col sm={6} md={2} lg={3}>
            <Form.Label>{props.label}</Form.Label>
          </Col>
          <Col sm={6} md={4} lg={4}>
            <Form.Control
              type="number"
              value={props.value}
              onChange={props.onChange}
              disabled={props.disabled}
            ></Form.Control>
          </Col>
          <Col sm={6} md={4} lg={2}>
            {props.unit}
          </Col>
        </Row>
      </Form.Group>
    );
  }
}

UniqueInputField.propTypes = {
  controlId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default UniqueInputField;
