import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import PropTypes from "prop-types"
import "./InputField.scss"

/**
 * This component renders 2 controlled input fields for the form
 * with the right props.
 */
class InputField extends Component {
  render() {
    const props = this.props;
    return (
      <Form.Group controlId={props.controlId}>
        <Row className="justify-content-center">
          <Col sm={6} md={2} lg={2} className="unitAndLabelCol">
            <Form.Label>{props.label}</Form.Label>
          </Col>
          <Col sm={6} md={2} lg={2}>
            <Form.Control
              type="number"
              value={props.valueConsumption}
              onChange={props.onChangeConsumption}
              disabled={props.disabledConsumption}
            ></Form.Control>
          </Col>
          <Col sm={6} md={2} lg={2} className="unitAndLabelCol">
            {props.unitConsumption}
          </Col>
          <Col sm={6} md={2} lg={2}>
            <Form.Control
              type="number"
              value={props.valueBill}
              onChange={props.onChangeBill}
              disabled={props.disabledBill}
            ></Form.Control>
          </Col>
          <Col sm={6} md={2} lg={2} className="unitAndLabelCol">
            {props.billUnit}
          </Col>
        </Row>
      </Form.Group>
    );
  }
}

InputField.propTypes = {
  controlId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  valueConsumption: PropTypes.string.isRequired,
  onChangeConsumption: PropTypes.func.isRequired,
  disabledConsumption: PropTypes.bool.isRequired,
  valueBill: PropTypes.string.isRequired,
  onChangeBill: PropTypes.func.isRequired,
  disabledBill: PropTypes.bool.isRequired,
}

export default InputField;
