export const CAR_TIP =
  "Cette rubrique vous permet de calculer les émissions de Gaz à Effet de Serre émises par vos véhicules. Veuillez choisir le type de carburant utilisé par vos véhicules. Vous pouvez ensuite sélectionner la  quantité consommée, ou bien le montant total acheté";
export const HOUSE_TIP =
  "Cette rubrique vous permet de calculer les émissions de Gaz à Effet de Serre émises au sein de votre  structure. Veuillez choisir le type de combustible utilisé par votre organisation. Vous pouvez ensuite  sélectionner la quantité consommée, ou bien le montant total acheté";
export const ELECTRIC_TIP =
  "Cette rubrique vous permet de calculer les émissions de Gaz à Effet de Serre liées à votre  consommation électrique.  Vous pouvez saisir votre consommation annuelle en KWH, qui figure sur  votre compteur électrique, les factures de votre fournisseur d’électricité. Vous pouvez également  nous fournir le montant total de vos factures électriques";
export const AIR_CONDITIONER_TIP =
  "Cette rubrique vous permet de calculer les émissions de Gaz à Effet de Serre liées à vos climatiseurs.  Veuillez saisir le nombre de climatiseurs présents dans votre entreprise, SustainEcho effectuera un  calcul à partir des taux de perte en Gaz.";
export const AIR_CONDITIONER_TIP2 =
  "Pour certaines installations, plus imposantes, SustainEcho analyse la quantité de Gaz achetée par  votre organisation. Si vous achetez différents gaz, vous pouvez remplir les champs correspondants. Pour les gaz que vous n'utilisez pas, laissez les champs vides.";
export const CO2_TIP =
  "Cette rubrique est celle dédiée aux émissions des processus industriels. Si vous estimez que votre  entreprise dégage ce type d’émissions, SustainEcho vous conseille de passer à la version payante de  sa solution.";
export const GLOBAL_TIP =
  "SustainEcho vous permet de mesurer les émissions de Gaz à Effet de Serre de votre organisation.  Vous devez remplir chaque catégorie du questionnaire, afin d’obtenir vos résultats.";
