import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import PropTypes from 'prop-types';

/**
 * This component renders the modal used for submitting
 * the datas and get the right graphs.
 * It needs the right props to be used by a parent component.
 */
class SubmitModal extends Component {
  render() {
    const props=this.props;
    return (
        <Modal show={props.showDialog}>
        <Modal.Header onHide={props.handleModalClosing}>
          Inscription
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={props.validated}>
            <Form.Group controlId="userForm">
              <Row className="justify-content-center">
              <Col sm={4} md={4} lg={4}>
                  <Form.Label>Nom de l'entreprise</Form.Label>
                </Col>
                <Col sm={8} md={8} lg={8}>
                  <Form.Control
                    type="text"
                    value={props.companyName}
                    onChange={props.handleUserCompanyName}
                  ></Form.Control>
                </Col>
                <Col sm={4} md={4} lg={4}>
                  <Form.Label>Secteur d'activité</Form.Label>
                </Col>
                <Col sm={8} md={8} lg={8}>
                  <Form.Control
                    type="text"
                    value={props.activitySector}
                    onChange={props.handleUserActivitySector}
                  ></Form.Control>
                </Col>
                <Col sm={4} md={4} lg={4}>
                  <Form.Label>Adresse mail</Form.Label>
                </Col>
                <Col sm={8} md={8} lg={8}>
                  <Form.Control
                    type="text"
                    value={props.mail}
                    onChange={props.handleUserMail}
                    required
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    L'email est requis
                  </Form.Control.Feedback>
                </Col>
                <Col sm={4} md={4} lg={4}>
                  <Form.Label>Nom</Form.Label>
                </Col>
                <Col sm={8} md={8} lg={8}>
                  <Form.Control
                    type="text"
                    value={props.name}
                    onChange={props.handleUserName}
                  ></Form.Control>
                </Col>
                <Col sm={4} md={4} lg={4}>
                  <Form.Label>Prénom</Form.Label>
                </Col>
                <Col sm={8} md={8} lg={8}>
                  <Form.Control
                    type="text"
                    value={props.firstName}
                    onChange={props.handleUserFirstName}
                  ></Form.Control>
                </Col>
                <Col sm={4} md={4} lg={4}>
                  <Form.Label>Mot de passe</Form.Label>
                </Col>
                <Col sm={8} md={8} lg={8}>
                  <Form.Control
                    type="password"
                    value={props.password}
                    onChange={props.handleUserPassword}
                  ></Form.Control>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleModalClosing}>
            Fermer
          </Button>
          <Button id="submitForm" variant="primary" onClick={props.handleSubmitSignInForm}>
            Obtenir les émissions
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

SubmitModal.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  handleModalClosing: PropTypes.func.isRequired,
  validated: PropTypes.bool.isRequired,
  companyName: PropTypes.string.isRequired,
  handleUserCompanyName: PropTypes.func.isRequired,
  activitySector: PropTypes.string.isRequired,
  handleUserActivitySector: PropTypes.func.isRequired,
  mail: PropTypes.string.isRequired,
  handleUserMail: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  handleUserName: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  handleUserFirstName: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  handleUserPassword: PropTypes.func.isRequired,
  handleSubmitSignInForm: PropTypes.func.isRequired,
};

export default SubmitModal;
