import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import PropTypes from 'prop-types';

/**
 * This component renders the modal used for submitting
 * the datas and get the right graphs.
 * It needs the right props to be used by a parent component.
 */
class ConnexionModal extends Component {
  render() {
    const props=this.props;
    return (
        <Modal show={props.showConnexion}>
        <Modal.Header onHide={props.handleModalClosing}>
          Connexion
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={props.validated}>
            <Form.Group controlId="userForm">
              <Row className="justify-content-center">
              <Col sm={4} md={4} lg={4}>
                  <Form.Label>Mail</Form.Label>
                </Col>
                <Col sm={8} md={8} lg={8}>
                  <Form.Control
                    type="text"
                    value={props.mail}
                    onChange={props.handleMail}
                  ></Form.Control>
                </Col>
                <Col sm={4} md={4} lg={4}>
                  <Form.Label>Mot de passe</Form.Label>
                </Col>
                <Col sm={8} md={8} lg={8}>
                  <Form.Control
                    type="password"
                    value={props.password}
                    onChange={props.handlePassword}
                  ></Form.Control>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleModalClosing}>
            Fermer
          </Button>
          <Button id="submitForm" variant="primary" onClick={props.connectUser}>
            Se connecter
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ConnexionModal.propTypes = {
  showConnexion: PropTypes.bool.isRequired,
  handleModalClosing: PropTypes.func.isRequired,
  validated: PropTypes.bool.isRequired,
  mail: PropTypes.string.isRequired,
  handleMail: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  handlePassword: PropTypes.func.isRequired,
  connectUser: PropTypes.func.isRequired,
};

export default ConnexionModal;
