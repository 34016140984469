import React, { Component, createRef } from "react";
import "./LandingPage.css";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Link from "react-router-dom/Link";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import logoSE from "../img/LogoSE.webp";
import ConnexionModal from "./ConnexionModal";
import { API_URL } from "../constants/Api_url";
import Axios from "axios";

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConnexion: false,
      mail: "",
      password: "",
      validatedForm: false,
    };
    this.connectUser = this.connectUser.bind(this);
    this.connectButton = createRef();
  }

  handleMail = (event) => {
    this.setState({ mail: event.target.value });
  };

  handlePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  openConnexion = (event) => {
    this.setState({ showConnexion: true });
  };

  handleModalClosing = () => {
    this.setState({ showConnexion: false });
  };

  /**
   * This method is used to connect the user by a call to the API.
   * It takes the data of the user in local state.
   */
  async connectUser() {
    if (this.state.mail && this.state.password) {
      const response = await Axios({
        method: "post",
        url: API_URL.concat("api_client/user_tableau/login/"),
        data: {
          email: this.state.mail,
          password: this.state.password,
        },
      }).then(response => {
        if(response.status === 200) {
          this.connectButton.current.click()
        } else {
          alert("Problème lors de la connexion, vérifiez vos identifiants")
        }
      });
      return response;
    } else {
      alert("Vérifiez que vous avez bien entré vos identifiants");
    }
  }

  render() {
    return (
      <Container fluid className="landingPageContainer">
        <Row className="mb-5">
          <Image src={logoSE} />
        </Row>
        <Row className="justify-content-center mb-5">
          <h1>
            SustainEcho vous propose une estimation gratuite, facile et rapide
            des émissions de votre entreprise.
          </h1>
        </Row>
        <Row className="justify-content-center mb-4">
          <h3>Prenez en main l'impact écologique de votre entreprise !</h3>
        </Row>
        <Row className="justify-content-center">
          <Link to="/Forms">
            <Button variant="success">Calculer mes émissions</Button>
          </Link>
        </Row>
        <Row className="justify-content-center mb-4 mt-4">
          <h3>
            Vous avez déjà commencé le calcul ? Reprenez là où vous vous en êtes
            arrêtés !
          </h3>
        </Row>
        <Row className="justify-content-center">
          <Button variant="success" onClick={this.openConnexion}>
            Reprendre l'estimation
          </Button>
        </Row>

        <Link to="/Forms">
          <Button variant="success" hidden ref={this.connectButton}>
          </Button>
        </Link>

        <ConnexionModal
          showConnexion={this.state.showConnexion}
          handleModalClosing={this.handleModalClosing}
          validated={this.state.validatedForm}
          mail={this.state.mail}
          handleMail={this.handleMail}
          password={this.state.password}
          handlePassword={this.handlePassword}
          connectUser={this.connectUser}
        />
      </Container>
    );
  }
}

export default LandingPage;
