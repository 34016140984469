/**
 * This array represents the datas for a graph by poste.
 * All the possible postes are listed here.
 */
export const POST_ARRAY = [
  "poste1",
  "poste2",
  "poste3",
  "poste4",
  "poste5",
  "poste6",
  "poste7",
  "poste8",
  "poste9",
  "poste10",
  "poste11",
  "poste12",
  "poste13",
  "poste14",
  "poste15",
  "poste16",
  "poste17",
  "poste18",
  "poste19",
  "poste20",
  "poste21",
  "poste22",
  "poste23",
  "poste24",
  "poste25",
  "poste26",
];
