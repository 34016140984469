import React, { Component } from "react";
import "./Forms.scss";
import Collapsible from "react-collapsible";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Axios from "axios";
import TitleBar from "./TitleBar"
import TopBar from "./TopBar"
import {CAR_TIP, HOUSE_TIP, ELECTRIC_TIP,
        AIR_CONDITIONER_TIP, AIR_CONDITIONER_TIP2, 
        CO2_TIP} from "../constants/TooltipTexts"
import {API_URL} from "../constants/Api_url"
import {CARBON_MAP} from "../constants/Carbon_map"
import {POST_ARRAY} from "../constants/PostArray"
import {FIXED_BOOLEANS} from "../constants/Fixed_booleans"
import "bootstrap/dist/css/bootstrap.min.css";
import InputField from "./InputField"
import UniqueInputField from "./UniqueInputField"
import CheckboxComponent from "./CheckboxComponent"
import SavingModal from "./Modals/SavingModal"
import SubmitModal from "./Modals/SubmitModal"
import RoundCheckbox from "./RoundCheckbox"
import BottomBar from "./BottomBar"


/**
 * This component renders the whole forms page. It contains a big state with all 
 * the form variables and all the boolean variables that control and design the form.
 * It also implements all the methods to retrieve the form datas, create and connect
 * a user, post the new items and get the corresponding item_carbone to display some graphs.
 */
class Forms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fioulConsumption: "",
      fioulBill:"",
      disablefioulConsumption: false,
      disablefioulBill: false,
      fioulUnit: "kWh",

      naturalGazConsumption: "",
      naturalGazBill:"",
      disablenaturalGazConsumption: false,
      disablenaturalGazBill: false,
      naturalGazUnit: "kWh",

      bioFuelConsumption: "",
      bioFuelBill: "",
      disablebioFuelConsumption: false,
      disablebioFuelBill: false,
      bioFuelUnit: "L",

      dieselConsumption: "",
      dieselBill: "",
      disabledieselConsumption: false,
      disabledieselBill: false,
      dieselUnit: "L",

      petrolConsumption: "",
      petrolBill: "",
      disablepetrolConsumption: false,
      disablepetrolBill: false,
      petrolUnit: "L",

      GPLConsumption: "",
      GPLBill: "",
      disableGPLConsumption: false,
      disableGPLBill: false,
      GPLUnit: "L",

      GNCConsumption: "",
      GNCBill:"",
      disableGNCConsumption: false,
      disableGNCBill: false,
      GNCUnit: "L",

      GNLConsumption: "",
      GNLBill:"",
      disableGNLConsumption: false,
      disableGNLBill: false,
      GNLUnit: "L",

      carBioFuelConsumption: "",
      carBioFuelBill: "",
      disablecarBioFuelConsumption: false,
      disablecarBioFuelBill: false,
      carBioFuelUnit: "L",

      airConditionerNumber: "",
      airConditionerUnit: "u",
      disableairConditionerNumber: false,

      R134aConsumption: "",
      R134aBill:"",
      disableR134aConsumption: false,
      disableR134aBill: false,

      R404aConsumption: "",
      R404aBill:"",
      disableR404aConsumption: false,
      disableR404aBill: false,

      R407cConsumption: "",
      R407cBill:"",
      disableR407cConsumption: false,
      disableR407cBill: false,

      R410aConsumption: "",
      R410aBill:"",
      disableR410aConsumption: false,
      disableR410aBill: false,

      R422dConsumption: "",
      R422dBill:"",
      disableR422dConsumption: false,
      disableR422dBill: false,

      R507Consumption: "",
      R507Bill:"",
      disableR507Consumption: false,
      disableR507Bill: false,

      gazUnit: "kg",

      electricConsumption: "",
      electricUnit: "kWh",
      electricBill: "",
      disableelectricConsumption: false,
      disableelectricBill: false,

      checkFuels: false,
      checkCarFuels: false,
      checkAirConditioner: false,
      checkAirConditionerGaz: false,
      checkCO2 : false,
      checkElectric : false,

      validFuels: false,
      validCarFuels:false,
      validAirConditioner: false,
      validCO2: false,
      validElectric: false,

      CO2Quantity: "",
      CO2Unit: "kgCO2e",
      disableCO2 : false,
      euroUnit: "Euros",
      showDialog1: false,
      showDialog2: false,
      user: {
        companyName : "",
        id: null,
        mail: "",
        name: "",
        firstName: "",
        password: "",
        activitySector: "",
        token: "",
      },
      formValidated: false,
      carbon_name_map: CARBON_MAP,
      postArray: POST_ARRAY,
      valueArray: [],
      progress : 0,
      enableSubmit: false,
    };
    this.getFormDatas = this.getFormDatas.bind(this);
    this.serializeFormDatas = this.serializeFormDatas.bind(this);
    this.handleSubmitSignInForm = this.handleSubmitSignInForm.bind(this);
    this.handleSaveSignInForm = this.handleSaveSignInForm.bind(this);
    this.InitializeGraph = this.InitializeGraph.bind(this)
    this.registerUser = this.registerUser.bind(this)
    this.connectUser = this.connectUser.bind(this)
    this.postDatas = this.postDatas.bind(this)
    this.getItemsCarbone= this.getItemsCarbone.bind(this)
    this.getFormDatas = this.getFormDatas.bind(this)
  }

  /**
   * This method initializes the graph abscissa by filling an array
   * containing all the correct values for the graph abscissa.
   */
  InitializeGraph(){
    var valueArray = [];
    for (let i = 1; i <= 26; i++) {
      valueArray.push(0);
    }
    this.setState({valueArray: valueArray });
  };

  componentDidMount() {
    // Removes the scroll incrementation/decrementation on number inputs
    document.addEventListener("wheel", function(event){
      if(document.activeElement.type === "number"){
          document.activeElement.blur();
      }
    });
    this.InitializeGraph();
  }

  //#region checkValue Methods
  /**
   * This method checks all the input values for the Fuels part.
   * If the part is checked as not applicable, it validates this part,
   * if there's at least one value in the text inputs, it validates this part,
   * Otherwise, it doesn't validate the part.
   * It also make the progressBar advance.
   */
  checkFuelsValue = () => {
    if((!this.state.fioulBill && !this.state.fioulConsumption
       && !this.state.naturalGazBill && !this.state.naturalGazConsumption
       && !this.state.bioFuelBill && !this.state.bioFuelConsumption) 
       && !this.state.checkFuels) {
        // Checks if that part was valid before
        if(this.state.validFuels) {
          let progress = this.state.progress;
          progress-=20;
          this.setState({validFuels : false, progress : progress, enableSubmit: false})
        }
       } else {
        //Checks if that part wasn't valid before
        if(!this.state.validFuels) {
          let progress = this.state.progress;
          progress+=20;
          this.setState({validFuels : true, progress : progress, enableSubmit : progress === 100})
        }
       }
  }

  /**
   * This method checks all the input values for the car Fuels part.
   * If the part is checked as not applicable, it validates this part,
   * if there's at least one value in the text inputs, it validates this part,
   * Otherwise, it doesn't validate the part.
   * It also make the progressBar advance.
   */
  checkCarFuelsValue = () => {
    if((!this.state.petrolBill && !this.state.petrolConsumption
        && !this.state.dieselBill && !this.state.dieselConsumption
        && !this.state.GPLBill && !this.state.GPLConsumption 
        && !this.state.GNCBill && !this.state.GNCConsumption
        && !this.state.GNLBill && !this.state.GNLConsumption
        && !this.state.carBioFuelBill && !this.state.carBioFuelConsumption) && !this.state.checkCarFuels) {
        // Checks if that part was valid before
      if(this.state.validCarFuels) {
        let progress = this.state.progress;
        progress-=20;
        this.setState({validCarFuels : false, progress : progress, enableSubmit: false})
      }
      } else {
        //Checks if that part wasn't valid before
        if(!this.state.validCarFuels) {
          let progress = this.state.progress;
          progress+=20;
          this.setState({validCarFuels: true, progress : progress, enableSubmit : progress === 100})
        }
      }
  }

  /**
   * This method checks all the input values for the air conditioner part.
   * If the part is checked as not applicable, it validates this part,
   * if there's at least one value in the text inputs, it validates this part,
   * Otherwise, it doesn't validate the part.
   * It also make the progressBar advance.
   */
  checkAirConditionerValue = () => {
    if((!this.state.airConditionerNumber && !this.state.checkAirConditioner) || 
      ((!this.state.R134aBill && !this.state.R134aConsumption 
      && !this.state.R404aBill && !this.state.R404aConsumption
      && !this.state.R407cBill && !this.state.R407cConsumption
      && !this.state.R410aBill && !this.state.R410aConsumption
      && !this.state.R422dBill && !this.state.R422dConsumption
      && !this.state.R507Bill && !this.state.R507Consumption) && !this.state.checkAirConditionerGaz)) {
        // Checks if that part was valid before
        if(this.state.validAirConditioner) {
          let progress = this.state.progress;
          progress-=20;
          this.setState({validAirConditioner: false, progress : progress, enableSubmit : false})
        }
      } else {
        //Checks if that part wasn't valid before
        if(!this.state.validAirConditioner) {
          let progress = this.state.progress;
          progress+=20;
          this.setState({validAirConditioner: true, progress : progress, enableSubmit : progress === 100})
        }
      }
  }

  /**
   * This method checks all the input values for the CO2 part.
   * If the part is checked as not applicable, it validates this part,
   * if there's at least one value in the text inputs, it validates this part,
   * Otherwise, it doesn't validate the part.
   * It also make the progressBar advance.
   */
  checkCO2Value = () => {
    if(!this.state.CO2Quantity && !this.state.checkCO2) {
      // Checks if that part was valid before
      if(this.state.validCO2) {
        let progress = this.state.progress;
        progress-=20;
        this.setState({validCO2: false ,progress : progress, enableSubmit: false})
      }
    } else {
      //Checks if that part wasn't valid before
      if(!this.state.validCO2) {
        let progress = this.state.progress;
        progress+=20;
        this.setState({validCO2: true, progress : progress, enableSubmit : progress === 100})
      }
    }
  }

  /**
   * This method checks all the input values for the Electric part.
   * If the part is checked as not applicable, it validates this part,
   * if there's at least one value in the text inputs, it validates this part,
   * Otherwise, it doesn't validate the part.
   * It also make the progressBar advance.
   */
  checkElectricValue = () => {
    if((!this.state.electricBill && !this.state.electricConsumption)
        && !this.state.checkElectric) {
        // Checks if that part was valid before
        if(this.state.validElectric) {
          let progress = this.state.progress;
          progress-=20;
          this.setState({validElectric: false, progress : progress, enableSubmit: false})
        }
      } else {
        //Checks if that part wasn't valid before
        if(!this.state.validElectric) {
          let progress = this.state.progress;
          progress+=20;
          this.setState({validElectric: true, progress : progress, enableSubmit : progress === 100})
        }
      }
  }
  //#endregion

  //#region handleMethods
   handleFioulConsumption = (event) => {
    if(event.target.value !== "") {
      this.setState({ fioulConsumption: event.target.value, disablefioulBill: true, checkFuels: false},  () => {this.checkFuelsValue()});
    } else {
      this.setState({ fioulConsumption: event.target.value, disablefioulBill: false, checkFuels: false},  () => {this.checkFuelsValue()});
    }
    
  };

  handleFioulBill = (event) => {
    if(event.target.value !== "") {
      this.setState({ fioulBill: event.target.value, disablefioulConsumption: true, checkFuels: false},  () => {this.checkFuelsValue()});
    } else {
      this.setState({ fioulBill: event.target.value, disablefioulConsumption: false, checkFuels: false},  () => {this.checkFuelsValue()});
    }
    
  };

  handleNaturalGazConsumption = (event) => {
    if(event.target.value !== "") {
      this.setState({ naturalGazConsumption: event.target.value, disablenaturalGazBill: true, checkFuels: false},  () => {this.checkFuelsValue()});
    } else {
      this.setState({ naturalGazConsumption: event.target.value, disablenaturalGazBill: false, checkFuels: false},  () => {this.checkFuelsValue()});
    }
    
  };

  handleNaturalGazBill = (event) => {
    if(event.target.value !== "") {
      this.setState({ naturalGazBill: event.target.value, disablenaturalGazConsumption: true, checkFuels: false},  () => {this.checkFuelsValue()});
    } else {
      this.setState({ naturalGazBill: event.target.value, disablenaturalGazConsumption: false, checkFuels: false},  () => {this.checkFuelsValue()});
    }
    
  };

  handleBioFuelConsumption = (event) => {
    if(event.target.value !== "") {
      this.setState({ bioFuelConsumption: event.target.value, disablebioFuelBill: true, checkFuels: false},  () => {this.checkFuelsValue()});
    } else {
      this.setState({ bioFuelConsumption: event.target.value, disablebioFuelBill: false, checkFuels: false},  () => {this.checkFuelsValue()});
    }
    
  };

  handleBioFuelBill = (event) => {
    if(event.target.value !== "") {
      this.setState({ bioFuelBill: event.target.value, disablebioFuelConsumption: true, checkFuels: false},  () => {this.checkFuelsValue()});
    } else {
      this.setState({ bioFuelBill: event.target.value, disablebioFuelConsumption: false, checkFuels: false},  () => {this.checkFuelsValue()});
    } 
  };

  handleElectricConsumption = (event) => {
    if(event.target.value !== "") {
      this.setState({ electricConsumption: event.target.value, disableelectricBill: true, checkElectric: false},  () => {this.checkElectricValue()});
    } else {
      this.setState({ electricConsumption: event.target.value, disableelectricBill: false, checkElectric: false},  () => {this.checkElectricValue()});
    }
  };

  handleElectricBill = (event) => {
    if(event.target.value !== "") {
      this.setState({ electricBill: event.target.value, disableelectricConsumption: true, checkElectric: false},  () => {this.checkElectricValue()});
    } else {
      this.setState({ electricBill: event.target.value, disableelectricConsumption: false, checkElectric: false},  () => {this.checkElectricValue()});
    }
    
  };

  handleDieselConsumption = (event) => {
    if(event.target.value !== "") {
      this.setState({ dieselConsumption: event.target.value, disabledieselBill: true, checkCarFuels: false },  () => {this.checkCarFuelsValue()});
    } else {
      this.setState({ dieselConsumption: event.target.value, disabledieselBill: false, checkCarFuels: false  },  () => {this.checkCarFuelsValue()});
    } 
  };

  handleDieselBill = (event) => {
    if(event.target.value !== "") {
      this.setState({ dieselBill: event.target.value, disabledieselConsumption : true, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    } else {
      this.setState({ dieselBill: event.target.value, disabledieselConsumption: false, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    }
    
  };

  handlePetrolConsumption = (event) => {
    if(event.target.value !== "") {
      this.setState({ petrolConsumption: event.target.value, disablepetrolBill: true, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    } else {
      this.setState({ petrolConsumption: event.target.value, disablepetrolBill: false, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    }
  };
  
  handlePetrolBill = (event) => {
    if(event.target.value !== "") {
      this.setState({ petrolBill: event.target.value, disablepetrolConsumption: true, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    } else {
      this.setState({ petrolBill: event.target.value, disablepetrolConsumption: false, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    }
    
  };

  handleGPLConsumption = (event) => {
    if(event.target.value !== "") {
      this.setState({ GPLConsumption: event.target.value, disableGPLBill: true, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    } else {
      this.setState({ GPLConsumption: event.target.value, disableGPLBill: false, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    } 
  };

  handleGPLBill = (event) => {
    if(event.target.value !== "") {
      this.setState({ GPLBill: event.target.value, disableGPLConsumption: true, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    } else {
      this.setState({ GPLBill: event.target.value, disableGPLConsumption: false, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    }
    
  };

  handleGNCConsumption = (event) => {
    if(event.target.value !== "") {
      this.setState({ GNCConsumption: event.target.value, disableGNCBill: true, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    } else {
      this.setState({ GNCConsumption: event.target.value, disableGNCBill: false, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    }
  };

  handleGNCBill = (event) => {
    if(event.target.value !== "") {
      this.setState({ GNCBill: event.target.value, disableGNCConsumption: true, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    } else {
      this.setState({ GNCBill: event.target.value, disableGNCConsumption: false, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    }
  };

  handleGNLConsumption = (event) => {
    if(event.target.value !== "") {
      this.setState({ GNLConsumption: event.target.value, disableGNLBill: true, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    } else {
      this.setState({ GNLConsumption: event.target.value, disableGNLBill: false, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    }
    
  };
  
  handleGNLBill = (event) => {
    if(event.target.value !== "") {
      this.setState({ GNLBill: event.target.value, disableGNLConsumption: true, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    } else {
      this.setState({ GNLBill: event.target.value, disableGNLConsumption: false, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    }
    
  };

  handleCarBioFuelConsumption = (event) => {
    if(event.target.value !== "") {
      this.setState({ carBioFuelConsumption: event.target.value, disablecarBioFuelBill: true, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    } else {
      this.setState({ carBioFuelConsumption: event.target.value, disablecarBioFuelBill: false, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    }  
  };

  handleCarBioFuelBill = (event) => {
    if(event.target.value !== "") {
      this.setState({ carBioFuelBill: event.target.value, disablecarBioFuelConsumption: true, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    } else {
      this.setState({ carBioFuelBill: event.target.value, disablecarBioFuelConsumption: false, checkCarFuels: false  }, () => {this.checkCarFuelsValue()});
    }
    
  };

  handleAirConditionerNumber = (event) => {
    this.setState({ airConditionerNumber: event.target.value, checkAirConditioner: false  }, () => {this.checkAirConditionerValue()});
  };

  handleR134aConsumption = (event) => {
    if(event.target.value !== "") {
      this.setState({ R134aConsumption: event.target.value, disableR134aBill: true, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    } else {
      this.setState({ R134aConsumption: event.target.value, disableR134aBill: false, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    }
    
  };
  
  handleR134aBill = (event) => {
    if(event.target.value !== "") {
      this.setState({ R134aBill: event.target.value, disableR134aConsumption: true, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    } else {
      this.setState({ R134aBill: event.target.value, disableR134aConsumption: false, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    }
    
  };

  handleR404aConsumption = (event) => {
    if(event.target.value !== "") {
      this.setState({ R404aConsumption: event.target.value, disableR404aBill: true, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    } else {
      this.setState({ R404aConsumption: event.target.value, disableR404aBill: false, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    }
  };

  handleR404aBill = (event) => {
    if(event.target.value !== "") {
      this.setState({ R404aBill: event.target.value, disableR404aConsumption: true, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    } else {
      this.setState({ R404aBill: event.target.value, disableR404aConsumption: false, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    }
    
  };

  handleR407cConsumption = (event) => {
    if(event.target.value !== "") {
      this.setState({ R407cConsumption: event.target.value, disableR407cBill: true, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    } else {
      this.setState({ R407cConsumption: event.target.value, disableR407cBill: false, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    }
    
  };

  handleR407cBill = (event) => {
    if(event.target.value !== "") {
      this.setState({ R407cBill: event.target.value, disableR407cConsumption: true, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    } else {
      this.setState({ R407cBill: event.target.value, disableR407cConsumption: false, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    }
    
  };

  handleR410aConsumption = (event) => {
    if(event.target.value !== "") {
      this.setState({ R410aConsumption: event.target.value, disableR410aBill: true, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    } else {
      this.setState({ R410aConsumption: event.target.value, disableR410aBill: false, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    }
  };

  handleR410aBill = (event) => {
    if(event.target.value !== "") {
      this.setState({ R410aBill: event.target.value, disableR410aConsumption: true, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    } else {
      this.setState({ R410aBill: event.target.value, disableR410aConsumption: false, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    }
    
  };

  handleR422dConsumption = (event) => {
    if(event.target.value !== "") {
      this.setState({ R422dConsumption: event.target.value, disableR422dBill: true, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    } else {
      this.setState({ R422dConsumption: event.target.value, disableR422dBill: false, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    }
    
  };

  handleR422dBill = (event) => {
    if(event.target.value !== "") {
      this.setState({ R422dBill: event.target.value, disableR422dConsumption: true, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    } else {
      this.setState({ R422dBill: event.target.value, disableR422dConsumption: false, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    }
    
  };

  handleR507Consumption = (event) => {
    if(event.target.value !== "") {
      this.setState({ R507Consumption: event.target.value, disableR507Bill: true, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    } else {
      this.setState({ R507Consumption: event.target.value, disableR507Bill: false, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    }
  };

  handleR507Bill = (event) => {
    if(event.target.value !== "") {
      this.setState({ R507Bill: event.target.value, disableR507Consumption: true, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    } else {
      this.setState({ R507Bill: event.target.value, disableR507Consumption: false, checkAirConditionerGaz: false   }, () => {this.checkAirConditionerValue()});
    }    
  };

  handleCO2Quantity = (event) => {
    this.setState({ CO2Quantity: event.target.value, checkCO2: false   }, () => {this.checkCO2Value()});
  };

  handleCheckFuels = (event) => {
    this.setState({
      checkFuels: !this.state.checkFuels}, () => {this.checkFuelsValue()});
    }
  handleCheckCarFuels = (event) => {
    this.setState({
      checkCarFuels: !this.state.checkCarFuels}, () => {this.checkCarFuelsValue()});
  }

  handleCheckAirConditioner = (event) => {
    this.setState({
      checkAirConditioner: !this.state.checkAirConditioner}, () => {this.checkAirConditionerValue()});
  }

  handleCheckCO2 = (event) => {
    this.setState({
      checkCO2: !this.state.checkCO2}, () => {this.checkCO2Value()});
  }

  handleCheckElectric = (event) => {
    this.setState({
      checkElectric: !this.state.checkElectric}, () => {this.checkElectricValue()});
  }

  handleCheckAirConditionerGaz = (event) => {
    this.setState({
      checkAirConditionerGaz: !this.state.checkAirConditionerGaz}, () => {this.checkAirConditionerValue()});
  }
  //#endregion

  leaveAndSubmit = (event) => {
    this.setState({ showDialog1: true });
  };

  leaveAndSave = (event) => {
    this.setState({ showDialog2: true });
  }

  //#region handleMethodsUser
  handleUserCompanyName = (event) => {
    const newCompanyName = event.target.value;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        companyName: newCompanyName,
      },
    }));
  }

  handleUserMail = (event) => {
    const newMail = event.target.value;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        mail: newMail,
      },
    }));
  };

  handleUserName = (event) => {
    const newName = event.target.value;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        name: newName,
      },
    }));
  };

  handleUserFirstName = (event) => {
    const newFirstName = event.target.value;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        firstName: newFirstName,
      },
    }));
  };

  handleUserPassword = (event) => {
    const newPassword = event.target.value;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        password: newPassword,
      },
    }));
  };

  handleUserActivitySector = (event) => {
    const newActivitySector = event.target.value;
    this.setState((prevState, event) => ({
      user: {
        ...prevState.user,
        activitySector: newActivitySector,
      },
    }));
  };
  //#endregion

  /**
   * This method is used to get the right data structure for the 
   * POST API. It gets a string as parameter which represents the 
   * data to post in the database and returns the good structure
   * for this data to add it to the API request with the getFormDatas.
   * @param {string} data string representing the data to post in the API.
   */
  serializeFormDatas(data) {
    var carbon_name;
    if(data.includes("Bill")) {
      carbon_name = this.state.carbon_name_map.get(data.slice(0,data.indexOf("Bill")).concat("Consumption"))
    } else {
      carbon_name = this.state.carbon_name_map.get(data)
    }
    const serializedData = {
      companydatabase: {
        nom: this.state.user.mail,
        etude: this.state.user.mail,
        groupe: null,
      },
      nom_carbone: carbon_name,
      item_quantity: {
        quant: parseInt(this.state[data], 10),
        unite: data.includes("Consumption") ? this.state[data.slice(0,data.indexOf("Consumption")).concat("Unit")] : this.state.euroUnit,
      },
      ...FIXED_BOOLEANS
    }
    console.log(serializedData)
    return serializedData;
  } 

  /**
   * This method is used to get all the datas from the form.
   * It returns an array with the right data structure for the POST request
   * with all the datas that were filled in the form.
   */
  getFormDatas() {
    const carbon_name_map = this.state.carbon_name_map;
    var datas = [];
    //#region datasFilling
    if(!this.state.checkFuels) {
      if (this.state.fioulConsumption !== "") {
        datas.push(this.serializeFormDatas("fioulConsumption"));
      }
      if (this.state.fioulBill !== "") {
        datas.push(this.serializeFormDatas("fioulBill"));
      }
      if (this.state.naturalGazBill !== "") {
        datas.push(this.serializeFormDatas("naturalGazBill"))
      }
      if (this.state.naturalGazConsumption !== "") {
        datas.push(this.serializeFormDatas("naturalGazBill"))
      }
      if (this.state.bioFuelConsumption !== "") {
        datas.push(this.serializeFormDatas("bioFuelConsumption"))
      }
      if (this.state.bioFuelBill !== "") {
        datas.push(this.serializeFormDatas("bioFuelBill"))
      }
      if (this.state.electricConsumption !== "") {
        datas.push(this.serializeFormDatas("electricConsumption"))
      }
      if (this.state.electricBill !== "") {
        datas.push(this.serializeFormDatas("electricBill"))
      }
    }
    if(!this.state.checkCarFuels) {
      if (this.state.dieselConsumption !== "") {
        datas.push(this.serializeFormDatas("dieselConsumption"))
    }
    if (this.state.dieselBill !== "") {
      datas.push(this.serializeFormDatas("dieselBill"))
    }
    if (this.state.petrolConsumption !== "") {
      datas.push(this.serializeFormDatas("petrolConsumption"))
    }
    if (this.state.petrolBill !== "") {
      datas.push(this.serializeFormDatas("petrolBill"))
    }
    if (this.state.GPLConsumption !== "") {
      datas.push(this.serializeFormDatas("GPLConsumption"))
    }
    if (this.state.GPLBill !== "") {
      datas.push(this.serializeFormDatas("GPLBill"))
    }
    if (this.state.GNCConsumption !== "") {
      datas.push(this.serializeFormDatas("GNCConsumption"))
    }
    if (this.state.GNCBill !== "") {
      datas.push(this.serializeFormDatas("GNCBill"))
    }
    if (this.state.GNLConsumption !== "") {
      datas.push(this.serializeFormDatas("GNLConsumption"))
    }
    if (this.state.GNLBill !== "") {
      datas.push(this.serializeFormDatas("GNLBill"))
    }
    if (this.state.carBioFuelConsumption !== "") {
      datas.push(this.serializeFormDatas("carBioFuelConsumption"))
    }
    if (this.state.carBioFuelBill !== "") {
      datas.push(this.serializeFormDatas("carBioFuelBill"))
    }
    }
    if(!this.state.checkAirConditioner) {
      if (this.state.airConditionerNumber !== "") {
        datas.push({
          companydatabase: {
            nom: this.state.user.mail,
            etude: this.state.user.mail,
            groupe: null,
          },
          nom_carbone: carbon_name_map.get("airConditionerNumber"),
          item_quantity: {
            quant: parseInt(this.state.airConditionerNumber, 10),
            unite: this.state.airConditionerUnit,
          },
          is_charge: false,
          is_produit: false,
          is_fixed: false,
          is_count: true,
        });
      }
      if (this.state.R134aConsumption !== "") {
        datas.push(this.serializeFormDatas("R134aConsumption"))
      }
      if (this.state.R134aBill !== "") {
        datas.push(this.serializeFormDatas("R134aBill"))
      }
      if (this.state.R404aConsumption !== "") {
        datas.push(this.serializeFormDatas("R404aConsumption"))
      }
      if (this.state.R404aBill !== "") {
        datas.push(this.serializeFormDatas("R404aBill"))
      }
      if (this.state.R407cConsumption !== "") {
        datas.push(this.serializeFormDatas("R407cConsumption"))
      }
      if (this.state.R407cBill !== "") {
        datas.push(this.serializeFormDatas("R407cBill"))
      }
      if (this.state.R410aConsumption !== "") {
        datas.push(this.serializeFormDatas("R410aConsumption"))
      }
      if (this.state.R410aBill !== "") {
        datas.push(this.serializeFormDatas("R410aBill"))
      }
      if (this.state.R422dConsumption !== "") {
        datas.push(this.serializeFormDatas("R422dConsumption"))
      }
      if (this.state.R422dBill !== "") {
        datas.push(this.serializeFormDatas("R422dBill"))
      }
      if (this.state.R507Consumption !== "") {
        datas.push(this.serializeFormDatas("R507Consumption"))
      }
      if (this.state.R507Bill !== "") {
        datas.push(this.serializeFormDatas("R507Bill"))
      }
    }
    if(!this.state.checkCO2) {
      if (this.state.CO2Quantity !== "") {
        datas.push({
          companydatabase: {
            nom: this.state.user.mail,
            etude: this.state.user.mail,
            groupe: null,
          },
          nom_carbone: carbon_name_map.get("CO2Quantity"),
          item_quantity: {
            quant: parseInt(this.state.CO2Quantity, 10),
            unite: this.state.CO2Unit,
          },
          is_charge: false,
          is_produit: false,
          is_fixed: false,
          is_count: true,
        });
      }
    }
    
    //#endregion
    return datas;
  };

  /**
   * This method is used to register the user by a call to the API.
   * It takes the data of the user in local state.
   */
  async registerUser() {
    const user = this.state.user
    var datas = {
      email: this.state.user.mail,
    };
    if (user.firstName !== "")
      datas = Object.assign(datas, { first_name: user.firstName });
    if (user.name !== "")
      datas = Object.assign(datas, { last_name: user.name });
    if (user.password !== "")
      datas = Object.assign(datas, { password: user.password });
      const response = await Axios({
        method: "post",
        url: API_URL.concat("api_client/user_tableau/"),
        data: datas,
      });
      return response;
    
  }

  /**
   * This method is used to connect the user by a call to the API.
   * It takes the data of the user in local state.
   */
  async connectUser() {
    const user = this.state.user
    if(user.password === "") {
      const response = await Axios({
        method: "post",
        url: API_URL.concat("api_client/user_tableau/login/"),
        data: {
          email: user.mail,
        },
      });
      return response
    } else {
      const response = await Axios({
        method: "post",
        url: API_URL.concat("api_client/user_tableau/login/"),
        data: {
          email: user.mail,
          password: user.password,
        },
      });
      return response
    }
  }

  /**
   * This method is used to post the form datas by a call to the API.
   * It takes the data of the getFormDatas() method.
   */
  async postDatas(datas) {
    const user=this.state.user
    const response = await Axios({
      method: "post",
      url: API_URL.concat("api_client/items_client/"),
      data: datas,
      headers: {
        Authorization: "JWT ".concat(user.token),
      },
    });
    return response
  }

  /**
   * This method is used to get the item_carbone corresponding to
   * the user in the local state by a call to the API.
   * It is useful to get the datas for the graphs.
   */
  async getItemsCarbone() {
    const user = this.state.user
    const response = await Axios({
      method: "get",
      url: API_URL.concat("api_client/items_carbone/"),
      params: { show: user.mail },
      headers: {
        Authorization: "JWT ".concat(user.token),
      },
    });
    return response
  }

  /**
   * This mehtod handles the submit of the signIn form.
   * It's available only if the form it entirely filled.
   * It will first register the user, connect him, post the form datas and 
   * finally get the corresponding item_carbone to be able to display the graphs
   * to the user.
   */
  async handleSubmitSignInForm() {
    const mail = this.state.user.mail;
    var graphValues = new Array(26);
    if (mail !== "" && mail.includes("@") && mail.includes(".")) {
      this.setState({ formValidated: true, showDialog1: false });
      this.registerUser().then(response => {
        if(response.status === 201 ){
          this.connectUser().then(response => {
            if(response.status === 200) {
              this.setState((prevState) => ({
                user: {
                  ...prevState.user,
                  id: response.data.id,
                  token: response.data.token,
                },
              }));
              const formDatas = this.getFormDatas();
              if (formDatas.length > 0) {
                this.postDatas(formDatas).then(response => {
                  if(response.status === 202) {
                    this.getItemsCarbone().then(response => {
                      for (const d of response.data) {
                        const index = d.poste.substring(5);
                        graphValues[index - 1] += d.valeur_co2;
                      }
                      this.setState({ valueArray: graphValues });
                      alert("Vos données ont bien été enregistrées.")
                    })
                  } else {
                    alert("Il y a eu un problème lors de l'enregistrement de vos données")
                  }
                })
              } else {
                alert("Vous n'avez rempli aucune donnée.");
              }
            } else {
              alert("Nous n'avons pas pu vous connecter");
            }
          })
        } else {
          alert(
            "Votre compte n'a pas pu être créé correctement, revérifiez vos informations"
          );
        }
      });
  }
}

  /**
   * This mehtod handles the submit of the signIn form if a user wants to save the 
   * datas he entered to finish it later.
   * It will first register the user, connect him, and post the actual form datas 
   * Then, the user will be able to connect and get back its datas (not yet implemented)
   */
  async handleSaveSignInForm() {
    const mail = this.state.user.mail;
    const password = this.state.user.password
    if (mail !== "" && mail.includes("@") && mail.includes(".") && password.length >7) {
      this.setState({formValidated: true, showDialog2: false});
      this.registerUser().then(response => {
        if(response.status === 201 ){
          this.connectUser().then(response => {
            if(response.status === 200) {
              this.setState((prevState) => ({
                user: {
                  ...prevState.user,
                  id: response.data.id,
                  token: response.data.token,
                },
              }));
              const formDatas = this.getFormDatas();
              if (formDatas.length > 0) {
                this.postDatas(formDatas).then(response => {
                  if(response.status === 202) {
                      alert("Vos données ont bien été enregistrées.")
                  } else {
                    alert("Il y a eu un problème lors de l'enregistrement de vos données")
                  }
                })
              } else {
                alert("Vous n'avez rempli aucune donnée.");
              }
            } else {
              alert("Nous n'avons pas pu vous connecter");
            }
          })
        } else {
          alert(
            "Votre compte n'a pas pu être créé correctement, revérifiez vos informations"
          );
        }
      });
  }
}

  

  handleModalClosing = () => this.setState({showDialog1: false, showDialog2: false});

  /**
   * This method renders the Forms component.
   * It is a complex render and displays a header bar, the big form with
   * collapsible parts and a footer with the submit button and a progress bar.
   * The header and the footer are always visible. The scroll is only on the
   * form part.
   */
  render() {
    const showDialog1 = this.state.showDialog1;
    const showDialog2 = this.state.showDialog2;
    return (
      <Container fluid className="customContainer">
        <TopBar leaveAndSave={this.leaveAndSave} />
        <Container fluid className="formContainer">
        <Row className="justify-content-center mt-4">
          <Row className="input-row">
            <Col xs={1} md={1} lg={1}>
              <RoundCheckbox checked={this.state.validFuels} />
            </Col>
            <Col xs={11} md={11} lg={11}>
              <Collapsible trigger="Consommation de carburant pour les machines fixes et bâtiments">
                <TitleBar title="Ma consommation de carburant domestique" tip={HOUSE_TIP} />
                <Form>
                  <Row className="justify-content-center">
                    <CheckboxComponent controlId="checkFuels"
                                       onChange={this.handleCheckFuels}
                                       checked={this.state.checkFuels}
                                       />
                  </Row>
                  <InputField label="Gaz Naturel" valueConsumption={this.state.naturalGazConsumption}
                              onChangeConsumption={this.handleNaturalGazConsumption} 
                              disabledConsumption={this.state.disablenaturalGazConsumption}
                              unitConsumption={this.state.naturalGazUnit}
                              valueBill={this.state.naturalGazBill}
                              onChangeBill={this.handleNaturalGazBill}
                              disabledBill={this.state.disablenaturalGazBill}
                              billUnit={this.state.euroUnit}
                              controlId="naturalGazConsumption"
                               />
                  <InputField label="Fioul domestique" valueConsumption={this.state.fioulConsumption}
                              onChangeConsumption={this.handleFioulConsumption} 
                              disabledConsumption={this.state.disablefioulConsumption}
                              unitConsumption={this.state.fioulUnit}
                              valueBill={this.state.fioulBill}
                              onChangeBill={this.handleFioulBill}
                              disabledBill={this.state.disablefioulBill}
                              billUnit={this.state.euroUnit}
                              controlId="fioulConsumption"
                               />
                  <InputField label="Biocarburant" valueConsumption={this.state.bioFuelConsumption}
                              onChangeConsumption={this.handleBioFuelConsumption} 
                              disabledConsumption={this.state.disablebioFuelConsumption}
                              unitConsumption={this.state.bioFuelUnit}
                              valueBill={this.state.bioFuelBill}
                              onChangeBill={this.handleBioFuelBill}
                              disabledBill={this.state.disablebioFuelBill}
                              billUnit={this.state.euroUnit}
                              controlId="bioFuelConsumption"
                               />
                </Form>
          </Collapsible>
          </Col> 
          </Row>
          <Row className="input-row">
          <Col xs={1} md={1} lg={1}>
              <RoundCheckbox checked={this.state.validCarFuels} />
            </Col>
            <Col xs={11} md={11} lg={11}>
              <Collapsible trigger="Consommation de carburant pour les véhicules">
                <TitleBar title="Ma consommation de carburant" tip={CAR_TIP}/>
                  <Form>
                  <Row className="justify-content-center">
                    <CheckboxComponent controlId="checkCarFuels"
                                       onChange={this.handleCheckCarFuels}
                                       checked={this.state.checkCarFuels}
                                       />
                    </Row>
                    <InputField label="Diesel" valueConsumption={this.state.dieselConsumption}
                              onChangeConsumption={this.handleDieselConsumption} 
                              disabledConsumption={this.state.disabledieselConsumption}
                              unitConsumption={this.state.dieselUnit}
                              valueBill={this.state.dieselBill}
                              onChangeBill={this.handleDieselBill}
                              disabledBill={this.state.disabledieselBill}
                              billUnit={this.state.euroUnit}
                              controlId="dieselConsumption"
                               />
                    <InputField label="Essence" valueConsumption={this.state.petrolConsumption}
                              onChangeConsumption={this.handlePetrolConsumption} 
                              disabledConsumption={this.state.disablepetrolConsumption}
                              unitConsumption={this.state.petrolUnit}
                              valueBill={this.state.petrolBill}
                              onChangeBill={this.handlePetrolBill}
                              disabledBill={this.state.disablepetrolBill}
                              billUnit={this.state.euroUnit}
                              controlId="petrolConsumption"
                               />
                    <InputField label="GPL" valueConsumption={this.state.GPLConsumption}
                              onChangeConsumption={this.handleGPLConsumption} 
                              disabledConsumption={this.state.disableGPLConsumption}
                              unitConsumption={this.state.GPLUnit}
                              valueBill={this.state.GPLBill}
                              onChangeBill={this.handleGPLBill}
                              disabledBill={this.state.disableGPLBill}
                              billUnit={this.state.euroUnit}
                              controlId="GPLConsumption"
                               />
                    <InputField label="GNC" valueConsumption={this.state.GNCConsumption}
                              onChangeConsumption={this.handleGNCConsumption} 
                              disabledConsumption={this.state.disableGNCConsumption}
                              unitConsumption={this.state.GNCUnit}
                              valueBill={this.state.GNCBill}
                              onChangeBill={this.handleGNCBill}
                              disabledBill={this.state.disableGNCBill}
                              billUnit={this.state.euroUnit}
                              controlId="GNCConsumption"
                               />
                    <InputField label="GNL" valueConsumption={this.state.GNLConsumption}
                              onChangeConsumption={this.handleGNLConsumption} 
                              disabledConsumption={this.state.disableGNLConsumption}
                              unitConsumption={this.state.GNLUnit}
                              valueBill={this.state.GNLBill}
                              onChangeBill={this.handleGNLBill}
                              disabledBill={this.state.disableGNLBill}
                              billUnit={this.state.euroUnit}
                              controlId="GNLConsumption"
                               />
                    <InputField label="Biocarburant" valueConsumption={this.state.carBioFuelConsumption}
                              onChangeConsumption={this.handleCarBioFuelConsumption} 
                              disabledConsumption={this.state.disablecarBioFuelConsumption}
                              unitConsumption={this.state.carBioFuelUnit}
                              valueBill={this.state.carBioFuelBill}
                              onChangeBill={this.handleCarBioFuelBill}
                              disabledBill={this.state.disablecarBioFuelBill}
                              billUnit={this.state.euroUnit}
                              controlId="carBioFuelConsumption"
                               />
                  </Form>
          </Collapsible>
          </Col>
          </Row>
          <Row className="input-row">
            <Col xs={1} md={1} lg={1}>
              <RoundCheckbox checked={this.state.validElectric} />
            </Col>
            <Col xs={11} md={11} lg={11}>
              <Collapsible trigger="Consommation d'électricité">
                <TitleBar title="Ma consommation" tip={ELECTRIC_TIP}/>
                <Form>
                <Row className="justify-content-center">
                    <CheckboxComponent controlId="checkElectric"
                                       onChange={this.handleCheckElectric}
                                       checked={this.state.checkElectric}
                                       />
                    </Row>
                  <InputField label="Electricité" valueConsumption={this.state.electricConsumption}
                              onChangeConsumption={this.handleElectricConsumption} 
                              disabledConsumption={this.state.disableelectricConsumption}
                              unitConsumption={this.state.electricUnit}
                              valueBill={this.state.electricBill}
                              onChangeBill={this.handleElectricBill}
                              disabledBill={this.state.disableelectricBill}
                              billUnit={this.state.euroUnit}
                              controlId="electricConsumption"
                               />
                </Form>
              </Collapsible>
            </Col>
            </Row>
          <Row className="input-row">
            <Col xs={1} md={1} lg={1}>
              <RoundCheckbox checked={this.state.validAirConditioner} />
            </Col>
            <Col xs={11} md={11} lg={11}>
            <Collapsible trigger="Climatisation">
              <TitleBar title="Réfrigération" tip={AIR_CONDITIONER_TIP} />
                <Form>
                <Row className="justify-content-center">
                    <CheckboxComponent controlId="checkAirConditioner"
                                       onChange={this.handleCheckAirConditioner}
                                       checked={this.state.checkAirConditioner} 
                                       />
                </Row>
                  <UniqueInputField controlId="airConditionerNumber"
                                    label="Nombre de climatiseurs : "
                                    value={this.state.airConditionerNumber}
                                    onChange={this.handleAirConditionerNumber}
                                    disabled={this.state.disableairConditionerNumber}
                                    unit={this.state.airConditionerUnit}
                                    />

                  <TitleBar title="Les gaz réfrigérants" tip={AIR_CONDITIONER_TIP2} />
                  <Row className="justify-content-center">
                    <CheckboxComponent controlId="checkAirConditionerGaz"
                                       onChange={this.handleCheckAirConditionerGaz}
                                       checked={this.state.checkAirConditionerGaz} 
                                       />
                  </Row>
                  <InputField label="R134a" valueConsumption={this.state.R134aConsumption}
                              onChangeConsumption={this.handleR134aConsumption} 
                              disabledConsumption={this.state.disableR134aConsumption}
                              unitConsumption={this.state.gazUnit}
                              valueBill={this.state.R134aBill}
                              onChangeBill={this.handleR134aBill}
                              disabledBill={this.state.disableR134aBill}
                              billUnit={this.state.euroUnit}
                              controlId="R134aConsumption"
                               />
                    <InputField label="R404a" valueConsumption={this.state.R404aConsumption}
                              onChangeConsumption={this.handleR404aConsumption} 
                              disabledConsumption={this.state.disableR404aConsumption}
                              unitConsumption={this.state.gazUnit}
                              valueBill={this.state.R404aBill}
                              onChangeBill={this.handleR404aBill}
                              disabledBill={this.state.disableR404aBill}
                              billUnit={this.state.euroUnit}
                              controlId="R404aConsumption"
                               />
                    <InputField label="R407c" valueConsumption={this.state.R407cConsumption}
                              onChangeConsumption={this.handleR407cConsumption} 
                              disabledConsumption={this.state.disableR407cConsumption}
                              unitConsumption={this.state.gazUnit}
                              valueBill={this.state.R407cBill}
                              onChangeBill={this.handleR407cBill}
                              disabledBill={this.state.disableR407cBill}
                              billUnit={this.state.euroUnit}
                              controlId="R407cConsumption"
                               />
                    <InputField label="R410a" valueConsumption={this.state.R410aConsumption}
                              onChangeConsumption={this.handleR410aConsumption} 
                              disabledConsumption={this.state.disableR410aConsumption}
                              unitConsumption={this.state.gazUnit}
                              valueBill={this.state.R410aBill}
                              onChangeBill={this.handleR410aBill}
                              disabledBill={this.state.disableR410aBill}
                              billUnit={this.state.euroUnit}
                              controlId="R410aConsumption"
                               />
                    <InputField label="R422d" valueConsumption={this.state.R422dConsumption}
                              onChangeConsumption={this.handleR422dConsumption} 
                              disabledConsumption={this.state.disableR422dConsumption}
                              unitConsumption={this.state.gazUnit}
                              valueBill={this.state.R422dBill}
                              onChangeBill={this.handleR422dBill}
                              disabledBill={this.state.disableR422dBill}
                              billUnit={this.state.euroUnit}
                              controlId="R422dConsumption"
                               />
                    <InputField label="R507" valueConsumption={this.state.R507Consumption}
                              onChangeConsumption={this.handleR507Consumption} 
                              disabledConsumption={this.state.disableR507Consumption}
                              unitConsumption={this.state.gazUnit}
                              valueBill={this.state.R507Bill}
                              onChangeBill={this.handleR507Bill}
                              disabledBill={this.state.disableR507Bill}
                              billUnit={this.state.euroUnit}
                              controlId="R507Consumption"
                               />
                </Form>
              </Collapsible>
            </Col>
          </Row>
          <Row className="input-row">
            <Col xs={1} md={1} lg={1}>
              <RoundCheckbox checked={this.state.validCO2} />
            </Col>
            <Col xs={11} md={11} lg={11}>
              <Collapsible trigger="Procédés industriels internes">
              <TitleBar title="Mes procédés industriels" tip={CO2_TIP}/>
                <Form>
                <Row className="justify-content-center">
                    <CheckboxComponent controlId="checkCO2"
                                       onChange={this.handleCheckCO2}
                                       checked={this.state.checkCO2} 
                                       />
                  </Row>
                  <UniqueInputField controlId="CO2Quantity"
                                    label="Quantité de CO2 rejetée : "
                                    value={this.state.CO2Quantity}
                                    onChange={this.handleCO2Quantity}
                                    disabled={this.state.disableCO2}
                                    unit={this.state.CO2Unit}
                                    />
                </Form>
              </Collapsible>
            </Col>
          </Row>
        </Row>
      </Container>  
      
      <BottomBar checked={this.state.enableSubmit}
                 onClick={this.leaveAndSubmit}
                 disabled={!this.state.enableSubmit}
                 progress={this.state.progress} 
                /> 

          <SubmitModal showDialog={showDialog1}
                       handleModalClosing={this.handleModalClosing}
                       validated={this.state.formValidated}
                       companyName={this.state.user.companyName}
                       handleUserCompanyName={this.handleUserCompanyName}
                       activitySector={this.state.user.activitySector}
                       handleUserActivitySector={this.handleUserActivitySector}
                       mail={this.state.user.mail}
                       handleUserMail={this.handleUserMail}
                       name={this.state.user.name}
                       handleUserName={this.handleUserName}
                       firstName={this.state.user.firstName}
                       handleUserFirstName={this.handleUserFirstName}
                       password={this.state.user.password}
                       handleUserPassword={this.handleUserPassword}
                       handleSubmitSignInForm={this.handleSubmitSignInForm}
                       />
          <SavingModal showDialog={showDialog2}
                       handleModalClosing={this.handleModalClosing}
                       validated={this.state.formValidated}
                       companyName={this.state.user.companyName}
                       handleUserCompanyName={this.handleUserCompanyName}
                       activitySector={this.state.user.activitySector}
                       handleUserActivitySector={this.handleUserActivitySector}
                       mail={this.state.user.mail}
                       handleUserMail={this.handleUserMail}
                       name={this.state.user.name}
                       handleUserName={this.handleUserName}
                       firstName={this.state.user.firstName}
                       handleUserFirstName={this.handleUserFirstName}
                       password={this.state.user.password}
                       handleUserPassword={this.handleUserPassword}
                       handleSaveSignIn={this.handleSaveSignInForm}
                       />
      </Container>
    );
  }
}

export default Forms;
