/**
 * This constant represents the right carbon_name for
 * all the inputs of the form. It is used to post the datas
 * on the API to get the corresponding item_carbone.
 */
export const CARBON_MAP = new Map ([
    ["naturalGazConsumption", "Gaz naturel | Moyen | France continentale"],
    ["fioulConsumption","Fioul domestique | Moyen | France continentale"],
    ["bioFuelConsumption","Essence | E85 | France continentale"],
    ["dieselConsumption","Gazole routier | Moyen | France continentale"],
    ["petrolConsumption","Essence | Supercarburant sans plomb (95, 95-E10, 98) | France continentale"],
    ["GPLConsumption","GPL pour véhicule routier | Moyen | France continentale"],
    ["GNCConsumption","GNC, Gaz Naturel Comprimé pour véhicule routier | Moyen | France continentale"],
    ["GNLConsumption","GNL, Gaz Naturel Liquéfié | (inclus routier, maritime et fluvial) | France continentale"],
    ["carBioFuelConsumption","Essence | E85 | France continentale"],
    ["electricConsumption","Electricité | 2018 - mix moyen | France continentale"],
    ["airConditionerNumber","climatiseur | moyen | France"],
    ["R404aConsumption","gaz climatisation | R404a | Monde"],
    ["R134aConsumption","gaz climatisation | R134a | Monde"],
    ["R407cConsumption","gaz climatisation | R407c | Monde"],
    ["R410aConsumption","gaz climatisation | R410a | Monde"],
    ["R422dConsumption","gaz climatisation | R422d | Monde"],
    ["R507Consumption","gaz climatisation | R507 | Monde"],
    ["CO2Quantity","gaz brut | CO2 | Monde"],
])
