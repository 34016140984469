import React, { Component } from "react";
import logoSE from "../img/LogoSE.webp";
import Image from "react-bootstrap/Image";
import InfoIcon from "@material-ui/icons/Info";
import { GLOBAL_TIP } from "../constants/TooltipTexts";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import PropTypes from "prop-types"
import "./TopBar.scss"

const styles = {
  tooltip: {
    fontSize: 15,
  },
};

const CustomTooltip = withStyles(styles)(Tooltip);

/**
 * This component renders the header bar of the application.
 * It contains the logo of the company, the header title with its tooltip
 * and a Saving button.
 */
class TopBar extends Component {
  render() {
    const props = this.props;
    return (
      <Row className="justify-content-between">
        <Col sm={4} md={4} lg={4}>
          <Image src={logoSE} />
        </Col>
        <Col
          sm={4}
          md={4}
          lg={4}
          className="mt-3"
          style={{ textAlign: "center" }}
        >
          <Row>
            <Col xs={1} md={1} lg={1}>
              <CustomTooltip title={GLOBAL_TIP} arrow placement="left">
                <InfoIcon style={{ color: "#82E6FC", height: "5vh" }} />
              </CustomTooltip>
            </Col>
            <Col xs={10} md={10} lg={11}>
              <h3>Mes émissions de Gaz à Effet de Serre</h3>
            </Col>
          </Row>
        </Col>
        <Col sm={4} md={4} lg={4} className="mt-3">
          <Button
            id="saveDatas"
            variant="custom"
            size="lg"
            style={{ float: "right" }}
            onClick={props.leaveAndSave}
          >
            Sauvegarder
          </Button>
        </Col>
      </Row>
    );
  }
}

TopBar.propTypes = {
    leaveAndSave: PropTypes.func.isRequired,
}

export default TopBar;
