import React, { Component } from "react";
import "./TitleBar.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types"

const styles = {
  tooltip : {
    fontSize:15
  }
}

const CustomTooltip = withStyles(styles)(Tooltip)

/**
 * This component is a wrapper for a title bar of the parts of the form.
 * It displays a tooltip with a text taken from props, a title taken from props
 * and a horizontal blue bar for styling.
 */
class TitleBar extends Component {
  render() {
    const props = this.props;
    return (
      <Row className="justify-content-between mb-2">
        <Col xs={6} md={5} lg={5}>
          <Row>
          <Col xs={1} md={1} lg={1}>
            <CustomTooltip
              title={props.tip}

              arrow
              placement="left"
            >
              <InfoIcon style = {{color: "#82E6FC"}} />
            </CustomTooltip>
            </Col>
            <Col xs={10} md={10} lg={10}>
            <h5>{props.title}</h5>
            </Col>
          </Row>
        </Col>
        <Col xs={6} md={6} lg={6} className="mr-4 mt-2">
          <div className="horizontalBar"></div>
        </Col>
      </Row>
    );
  }
}

TitleBar.propTypes = {
  tip: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default TitleBar;
