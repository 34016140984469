import React, { Component } from "react";
import PropTypes from "prop-types"
import "./RoundCheckbox.scss"

/**
 * This component renders a readOnly checkbox component
 * in order to validate the part of a form.
 */
class RoundCheckbox extends Component {
    render() {
        const props=this.props;
        return(
            <div className="round">
                <input type="checkbox" id="checkbox" checked={props.checked} readOnly />
                <label htmlFor="checkbox"></label>
            </div>
        )
    }
}

RoundCheckbox.propTypes = {
    checked : PropTypes.bool.isRequired,
}

export default RoundCheckbox;