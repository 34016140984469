import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Forms from "./Forms/Forms"
import LandingPage from "./LandingPage/LandingPage"

/**
 * Entry point of the application
 * This component shows the landing page and then redirects user on the click on a button
 */
class Index extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route path="/Forms">
            <Forms />
          </Route>
        </Switch>
      </Router>
    );
  }
}

ReactDOM.render(<Index />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
